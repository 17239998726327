import React, { useEffect, useMemo, useRef } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { PageProps } from 'gatsby'
import slugify from 'slugify'
import { FacebookProvider } from 'react-facebook'
import { Helmet } from 'react-helmet'
import { REPORTER } from 'src/services/category/category'

// Hook
import useWidth from 'src/hooks/useWidth'
import isVisible from 'src/utils/isVisible'
import useScroll from 'src/hooks/useScroll'

// Components
import Layout from 'src/components/Layout'
import Text from 'src/components/ContentBlocks/Text'
import Media from 'src/components/ContentBlocks/Media'
import TextImage from 'src/components/ContentBlocks/TextImage'
import FAQ from 'src/components/ContentBlocks/FAQ'
import Citation from 'src/components/ContentBlocks/Citation'
import Button from 'src/components/ContentBlocks/button'
import SEO from 'src/components/Seo'
import PageTitle from 'src/components/PageTitle'
import Columnists from 'src/components/Columnists'
import FloatingSocialMedia from 'src/components/FloatingSocialMedia'
import Summary from 'src/components/Summary'
import CategoryHeader from 'src/components/CategoryHeader'
import SpotlightNewsSmall from 'src/components/SpotlightNewsSmall'
import { SpotlightNewsSmallField } from 'src/services/articles/articles'
import Tags from 'src/components/Tags'

// import PrevNextNews from 'src/components/PrevNextNews'
// import RelatedNews from 'src/components/RelatedNews'

import FloatingSocialMediaSharer from 'src/components/FloatingSocialMedia/assets/data/FloatingSocialMediaSharer.json'
import useDomReady from 'src/hooks/useDomReady'
import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'
import InternalNewsletter from 'src/components/Newsletter/Internal'

// Data
import authorsJSON from 'src/assets/data/author.json'
// Types

import {
  Post,
  PostText,
  PostVideo,
  PostTextImage,
  PostImage,
  PostSubText,
  PostFaq,
  PostCitation,
  PostButton,
} from 'src/types/Post'
// Styles

import { useTheme } from 'styled-components'

import { Banner, ArticleContent, BlockSection, SocialMediaFloat } from './style'

type AllPostsTypes =
  | PostText
  | PostVideo
  | PostTextImage
  | PostImage
  | PostSubText
  | PostFaq
  | PostCitation
  | PostButton

type ArticleProps = {
  pageContext: Post
} & PageProps

const generateBlogPostSchema = (postData: Post) =>
  JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${process.env.BASE_URL}/${postData.slug}`,
    },
    headline: postData.title,
    description: postData.introduction,
    image: [postData.gridImageUrl, postData.banner_image_url],
    author: {
      '@type': 'Organization',
      name: 'Inter',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Inter',
      logo: {
        '@type': 'ImageObject',
        url: 'https://marketing.bancointer.com.br/comunicacao/e-mail-mkt-institucional/imagens/banco-inter.png',
      },
    },
    datePublished: postData.publishedAt,
    dateModified: postData.updatedAt,
  })

const Article = ({ pageContext }: ArticleProps) => {
  const theme = useTheme()
  const domReady = useDomReady()
  const [sendDatalayerEvent] = useDataLayer()
  const { category, subcategoria, isFromInset, insetCategory } = pageContext
  const posts = pageContext.fileContents
  const author = authorsJSON.find(
    (columnist) =>
      columnist.id === pageContext.fileContents.authorId &&
      pageContext.fileContents.authorId.toString(),
  )
  const scroll = useScroll(300)
  const floatingSideSocialMedia = useRef<HTMLDivElement>(null)
  const breadcrumbPath = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: `/${category.slug}`,
      text: category.name,
    },
    {
      link: `/${category.slug}/${subcategoria.slug}`,
      text: subcategoria.name,
    },
  ]

  if (category) {
    breadcrumbPath.push({
      link: posts.slug,
      text: posts.title,
    })
  }
  const width = useWidth()

  function fillComponentStyle(type: 'articleHeader' | 'articleFooter') {
    return {
      theme,
      type,
    }
  }
  function returnPreSlug() {
    if (author?.type === REPORTER) {
      return '/reporteres/'
    }
    return '/colunistas/'
  }
  const dataColumnist = {
    preSlug: returnPreSlug(),
    data: author,
    componentStyle: fillComponentStyle('articleHeader'),
    articleId: posts.id,
  }

  function handleIndex(id: string, index: number) {
    return `${id}-${index}`
  }

  useEffect(() => {
    if (isVisible(floatingSideSocialMedia.current, -0.3) && floatingSideSocialMedia.current) {
      floatingSideSocialMedia.current.style.top = '120px'
    }
  }, [scroll])

  const postSchema = useMemo(() => generateBlogPostSchema(pageContext), [pageContext])
  useEffect(() => {
    if (!domReady || !window.dataLayer) {
      return
    }
    window.dataLayer.push({
      event: 'articleView',
      materia: posts.title,
    })
  }, [domReady])

  return (
    <FacebookProvider appId={process.env.FACEBOOK_APP_ID}>
      <Layout className="menu-bg-orange" pageContext={pageContext}>
        <SEO
          title={`${posts.seo_title || posts.title} · Inter Invest`}
          url={
            !isFromInset
              ? `${process.env.BASE_URL}/${category.slug}/${subcategoria.slug}/${posts.slug}`
              : `www.inset.com.br/${insetCategory}/${posts.slug}`
          }
          meta={[
            {
              property: 'fb:app_id',
              content: process.env.FACEBOOK_APP_ID,
            },
            {
              name: 'description',
              content: posts.seo_description || posts.og_description || posts.introduction,
            },
            {
              property: 'og:title',
              content: `${posts.og_title || posts.seo_title || posts.title} · Inter Invest`,
            },
            {
              property: 'og:description',
              content: posts.og_description || posts.seo_description || posts.introduction,
            },
            {
              property: 'og:image',
              content: posts.og_image || posts.homeImage.url || posts.gridImage.url,
            },
            {
              property: 'image',
              content: posts.og_image || posts.homeImage.url || posts.gridImage.url,
            },
            {
              property: 'og:image:width',
              content: posts.og_image_width || (posts.homeImage.url ? '750' : '370'),
            },
            {
              property: 'og:image:height',
              content: posts.og_image_height || (posts.homeImage.url ? '460' : '260'),
            },
            {
              property: 'og:locale',
              content: 'pt_BR',
            },
            {
              property: 'og:type',
              content: 'article',
            },
            {
              property: 'og:url',
              content: `${process.env.BASE_URL}/${category.slug}/${subcategoria.slug}/${posts.slug}`,
            },
            {
              property: 'article:publisher',
              content: 'https://www.facebook.com/inter-invest',
            },
            {
              property: 'article:section',
              content: `${category?.name}`,
            },
            {
              property: 'article:published_time',
              content: posts.publishedAt,
            },
            {
              property: 'article:modified_time',
              content: posts.updatedAt,
            },
            {
              property: 'og:updated_time',
              content: posts.updatedAt,
            },
            {
              property: 'og:site_name',
              content: 'Banco Inter',
            },
            {
              property: 'twitter:image:src',
              content: posts.og_image || posts.homeImage.url || posts.gridImage.url,
            },
            {
              property: 'twitter:card',
              content: 'summary',
            },
            {
              property: 'twitter:site',
              content: '@bancointer',
            },
            {
              property: 'twitter:title',
              content: posts.twitter_title || posts.og_title || posts.title,
            },
            {
              property: 'twitter:description',
              content: posts.twitter_description || posts.og_description || posts.introduction,
            },
            {
              property: 'twitter:creator',
              content: '@bancointer',
            },
            {
              property: 'categoria',
              content: category?.name,
            },
          ]}
        />
        <Helmet>
          <script type="application/ld+json">{postSchema}</script>
        </Helmet>
        <Banner className="d-flex">
          <div className="container">
            <div className="row align-items-center">
              <PageTitle
                breadcrumb={{ path: breadcrumbPath }}
                pageTitle={category?.name ? category.name : ''}
              />
            </div>
            <hr className="d-none d-md-block" />
          </div>
        </Banner>
        <div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8">
                <h1 className="fs-24 lh-30 fs-md-56 lh-md-70 text-grayscale--500">{posts.title}</h1>
                <div className="d-flex justify-content-between mb-5">
                  {dataColumnist ? <Columnists {...dataColumnist} /> : ''}
                  {width >= 768 ? (
                    <FloatingSocialMedia
                      pageUrl={`${process.env.BASE_URL}/${category.slug}/${subcategoria.slug}/${posts.slug}`}
                      sharer
                      items={FloatingSocialMediaSharer}
                      pageContext={pageContext}
                      sharer
                      direction="row"
                    />
                  ) : (
                    ''
                  )}
                </div>
                {posts.summary ? <Summary content={posts.summary} /> : ''}
              </div>
            </div>
          </div>
        </div>
        <ArticleContent id={posts.slug}>
          <div className="container">
            {width >= 992 ? (
              <SocialMediaFloat ref={floatingSideSocialMedia}>
                <FloatingSocialMedia
                  pageUrl={`${process.env.BASE_URL}/${category.slug}/${subcategoria.slug}/${posts.slug}`}
                  items={FloatingSocialMediaSharer}
                  pageContext={pageContext}
                  sharer
                  direction="col"
                />
              </SocialMediaFloat>
            ) : (
              ''
            )}
            <div className="row justify-content-center ">
              <div className="col-12 col-lg-10 col-xl-8">
                {posts.contentBlocks.map((contentBlock: AllPostsTypes, index: number) => {
                  switch (contentBlock.type) {
                    case 'text':
                    case 'html': {
                      const slug = slugify((contentBlock as PostText).content.title, {
                        lower: true,
                        strict: true,
                      })
                      return (
                        <BlockSection key={handleIndex(posts.id, index)}>
                          <Text
                            slug={`ir-para-${slug}`}
                            title={(contentBlock as PostText).content.title}
                            text={(contentBlock as PostText).content.text}
                            scroll={scroll}
                          />
                        </BlockSection>
                      )
                    }
                    case 'image':
                    case 'video':
                      return (
                        <BlockSection key={handleIndex(posts.id, index)}>
                          <Media
                            type={(contentBlock as PostImage | PostVideo).type}
                            url={(contentBlock as PostImage | PostVideo).content.url}
                            caption={(contentBlock as PostImage | PostVideo).content.caption}
                            alt={(contentBlock as PostImage | PostVideo).content.alt}
                            maxWidth={(contentBlock as PostImage | PostVideo).content.maxWidth}
                            zoomEnabled={
                              (contentBlock as PostImage | PostVideo).content.zoom_enabled
                            }
                          />
                        </BlockSection>
                      )
                    case 'text-image': {
                      const slug =
                        contentBlock.content.title &&
                        slugify((contentBlock as PostTextImage).content.title, {
                          lower: true,
                          strict: true,
                        })
                      return (
                        <BlockSection key={handleIndex(posts.id, index)}>
                          <TextImage
                            slug={`ir-para-${slugify(slug)}`}
                            title={(contentBlock as PostTextImage).content.title}
                            text={(contentBlock as PostTextImage).content.text}
                            image_url={(contentBlock as PostTextImage).content.image_url}
                            image_caption={(contentBlock as PostTextImage).content.image_caption}
                            image_alignment={
                              (contentBlock as PostTextImage).content.image_alignment
                            }
                            scroll={scroll}
                            zoomEnabled={
                              (contentBlock as PostImage | PostVideo).content.image_zoom_enabled
                            }
                          />
                        </BlockSection>
                      )
                    }
                    case 'faq': {
                      const slug = slugify((contentBlock as PostFaq).content.title, {
                        lower: true,
                        strict: true,
                      })
                      return (
                        <BlockSection key={handleIndex(posts.id, index)}>
                          <FAQ
                            title={(contentBlock as PostFaq).content.title}
                            slug={`ir-para-${slug}`}
                            columns={{
                              sm: 1,
                              md: 1,
                              lg: 1,
                              xl: 1,
                            }}
                            data={(contentBlock as PostFaq).content.topics}
                          />
                        </BlockSection>
                      )
                    }
                    case 'citation':
                      return (
                        <BlockSection key={handleIndex(posts.id, index)}>
                          <Citation
                            author={(contentBlock as PostCitation).content.author}
                            text={(contentBlock as PostCitation).content.text}
                          />
                        </BlockSection>
                      )
                    case 'button': {
                      const slug = slugify((contentBlock as PostTextImage).content.title, {
                        lower: true,
                        strict: true,
                      })
                      return (
                        <BlockSection key={handleIndex(posts.id, index)}>
                          <Button
                            slug={`ir-para-${slug}`}
                            path={(contentBlock as PostButton).content.path}
                            title={(contentBlock as PostButton).content.title}
                          />
                        </BlockSection>
                      )
                    }
                    case 'file':
                      return (
                        <BlockSection key={handleIndex(posts.id, index)}>
                          <a
                            href={contentBlock.content.file.url}
                            target="__blank"
                            rel="noreferrer"
                            onClick={() => {
                              sendDatalayerEvent({
                                section: contentBlock.content,
                                element_action: 'click button',
                                element_name: contentBlock.content.description,
                                redirect_url: contentBlock.content.file.url,
                              })
                            }}
                          >
                            <div className="d-flex">
                              <div className="mr-3">
                                <OrangeDsIcon icon="download" size="MD" color="#ff7a00" />
                              </div>
                              <p>{contentBlock.content.description}</p>
                            </div>
                          </a>
                        </BlockSection>
                      )
                    default:
                      return <div key={handleIndex(posts.id, index)}>[Content Not Mapped]</div>
                  }
                })}
              </div>
            </div>
          </div>
        </ArticleContent>
        <div className="container mb-3 mb-lg-0">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8">
              <Tags tags={posts.tags} />
              <div className="d-md-flex justify-content-between mt-5">
                <hr className="d-md-none" />
                <div>
                  <h4 className="fs-14 lh-17 fw-700 text-center text-md-left text-grayscale--500">
                    Compartilhe essa notícia
                  </h4>
                  <FloatingSocialMedia
                    pageUrl={`${process.env.BASE_URL}/${category.slug}/${subcategoria.slug}/${posts.slug}`}
                    items={FloatingSocialMediaSharer}
                    sharer
                    pageContext={pageContext}
                    direction="row"
                    justifyContent="justify-content-around"
                  />
                </div>
              </div>
              {posts.relatedNews.length > 0 && (
                <div className="mt-5">
                  <CategoryHeader category="Notícias Relacionadas" className="mb-3" />
                  <SpotlightNewsSmall
                    data={posts.relatedNews.map((item) => SpotlightNewsSmallField(item))}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <InternalNewsletter
          page={category.name}
          category={category.name}
          subCategory={subcategoria.name}
        />
      </Layout>
    </FacebookProvider>
  )
}

export default Article
